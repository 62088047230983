@import "./colors.scss";
@import "./fonts.scss";
@import "./activate-account.scss";
@import "./create-token.scss";
@import "./tokens.scss";

label {
  font-family: "Glacial Indifference";
  font-size: 14px;
  font-weight: bold;
  display: block;
  &.blue {
    color: $darkBlue;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  &.grey {
    color: $grey;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

span svg {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-center {
  align-items: center;
}
h4 {
  font-family: "Glacial Indifference";
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  color: #646464;
}

h3 {
  color: $darkBlue;
  font-family: "Glacial Indifference";
  font-weight: bold;

  &.card-label {
    font-size: 20px;
  }

  &.blue-text {
    font-size: 25px;
    text-align: center;
  }
}

h1 {
  &.yellow-text {
    font-family: "Glacial Indifference";
    font-size: 77px;
    color: #ffb93e;
    font-weight: 800;
    margin-bottom: 0;
    text-align: center;
  }
}

.percentage {
  font-family: "Glacial Indifference";
  font-size: 15px;
  color: #17db45;
  text-align: right;
  font-size: bold;
}

.align-center {
  align-items: center;
  justify-content: center;
}

.sticky-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
  box-shadow: 1px -1px 6px #000000b8;
  z-index: 12;
  position: sticky;
  top: 0;
}

.ant-modal-header {
  background-color: $darkBlue;
  > div {
    color: white !important;
  }
}
