.content-container {
  // background: $grey;
  padding: 0 0 2rem 0;
}

.ant-card-body {
  // padding-bottom: 0; commented as its overriding all card
}

.page-description-container {
  max-width: 62.5rem;
  margin: auto;
  padding: 1.3125rem 1rem 0 1rem;

  .page-description-title {
    font-family: "Glacial Indifference";
    font-size: 1.75rem;
    font-weight: 600;
    color: $grey;
    width: 100%;
    display: block;
    text-transform: uppercase;
  }

  .page-description-subtitle {
    font-family: "Gilroy";
    font-size: 1.0625rem;
    font-weight: 200;
    max-width: 78ch;
    line-height: 1.2352941176470589em;
    margin: 1.0625rem 0 0 0;
    color: $grey;
  }
}

.content-paragraph-container {
  margin: 0.9rem 0 0 0;
  padding: 0 0 0 2rem;

  .content-paragraph {
    color: $grey;
    font-size: 1.75rem;
    font-family: var(--font-glacial);
    font-weight: 200;
    margin-bottom: 2rem;
  }

  .content-title {
    font-family: var(--font-glacial);
    font-size: 2.5rem;
    color: $blue;
    font-weight: 800;
  }
}

.svg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .confirm-email-svg {
    max-width: 34.06rem;
    width: 100%;
  }
}

.anchor-text {
  text-decoration: underline;
  color: $grey;
  font-size: 1.75rem;
  font-family: var(--font-glacial);
  font-weight: 200;
}
