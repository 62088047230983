.checkbox-group {
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Glacial Indifference';
    font-size: 11pt;
    color: #777777;
    font-weight: 200;
  }
}

.token-history {
  font-family: "Glacial Indifference";
  .token-amount {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.01em;
    line-height: 13px;
    text-align: left;
    color: #535353;
    opacity: 0.67;
  }

  .token-time {
    font-weight: normal;
    font-size: 12px;
    text-align: right;
    color: #969696;
  }
}

.horizontal-select-bar {
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text-label-med-style {
  font-family: 'Glacial Indifference';
  font-size: 11pt;
  color: #777777;
  font-weight: 200;
}

.text-label-header-style {
  font-family: 'Glacial Indifference';
  color: #828282;
  font-size: 15pt;
  font-weight: 200;
}

.ant-radio-wrapper {
  font-family: 'Glacial Indifference';
  font-size: 11pt;
  color: #777777;
  font-weight: 200;
}

.form-space {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-form-item-label > label {
  font-family: 'Glacial Indifference';
  font-size: 11pt;
  color: #777777;
  font-weight: 200;
}

.align-total-amount {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #505050;
}

.book-activity-btn {
  margin: 0 auto;
}

.import-button-spacing {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.excel-file-tag {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: green;
}

.modal-button-format {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-import-text {
  background-color: $darkBlue;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  color: white;
}

.modal-format-header {
  font-family: var(--font-glacial);
  font-size: 18pt;
  font-weight: bold;
}

.modal-format-body {
  font-family: var(--font-glacial);
  font-size: 14pt;
  font-weight: 200;
}

.token-history-header {
  font-family: var(--font-glacial);
  font-size: 15pt;
  font-weight: 600;
  color: $grey51;
}

.token-history-label-amount {
  font-family: var(--font-glacial);
  font-size: 11pt;
  font-weight: 200;
  color: $grey53;
}

.token-history-list-header {
  font-family: var(--font-glacial);
  font-size: 23pt;
  font-weight: bold;
  color: $grey64;
}

.token-history-list-header-spacing {
  padding-top: 10px;
  padding-bottom: 10px;
}

.token-avatar-container {
  position: relative;

  .token-avatar {
    border: 0.125rem solid $purple;
  }

  .ant-badge {
    position: absolute;
    left: 0;

    .ant-badge-status-dot {
      --size: 0.625rem;
      border: 0.03125rem solid $white;
      width: var(--size);
      height: var(--size);
    }
  }
}

.amount-modal-footer {
  display: flex;
  justify-content: space-around;
}

.file-upload-style {
  width: 100%;
  border: 1px solid $grey64;
  height: 30px;
  border-radius: 7px;
  align-items: center;
}

// .ant-upload.ant-upload-select {
//   display: inline-block;
//   width: 100%;
//   border: 1px solid $grey51;
//   border-radius: 7px;
//   height: 25px;
// }

.create-token-upload-button {
  border-color: $grey51;
  border-radius: 0.5625rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
}

.sc-jrQzAO .eDXWjE {
  display: inline-block;
  background-color: aquamarine;
  width: 100%;
}

.scroll-table-view {
  max-height: 40vh;
  overflow-y: scroll;
}