.create-token-section {
    padding: 20px;
    .dashboard-token {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .dashboard-token-icon {
            margin-bottom: 20px;
        }
    }
}
