$shadow: #00000029;
$transparent: transparent;
$black: #000000;
$white: #ffffff;
$yellow: #ffb93e;
$green: #3e9363;
$lightGreen: #86d693;
$violet: #121a64;
$salmon: #f7a58d;
$grey: #b1b1b1;
$pink: #fdddd5;
$aqua: #8ce2d0;
$blue: #0066ff;
$turquoise: #74d2e7;
$lightBlue: #8bd3e6;
$brightBlue: #3a00ff;
$darkBlue: #003466;
$purple: #0001af;
$lightPurple: #8f8ce2;
$darkSalmon: #e1b7a7;
$red: #ee1a1a;
$grey51: #515151;
$grey53: #535353;
$grey64: #646464;