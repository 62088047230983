@font-face {
    font-family: "Gilroy";
    src: url("Gilroy/Gilroy-ExtraBold.otf") format("opentype");
    font-style: bold;
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("Gilroy/Gilroy-Bold.ttf") format("opentype");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("Gilroy/Gilroy-Medium.ttf") format("opentype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("Gilroy/Gilroy-Light.otf") format("opentype");
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "Glacial Indifference";
    src: url("GlacialIndifference/GlacialIndifference-Regular.otf") format("opentype");
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "Glacial Indifference";
    src: url("GlacialIndifference/GlacialIndifference-Italic.otf") format("opentype");
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Glacial Indifference";
    src: url("GlacialIndifference/GlacialIndifference-Bold.otf") format("opentype");
    font-style: bold;
    font-weight: 800;
    font-display: swap;
}
